import React from "react";
import { Album, PlaybackState, SpotifyApi, UserProfile } from "@spotify/web-api-ts-sdk";

export interface PlayingItem {
  album: Album
  name: string
}

export const useSdk = () => {
  const [ sdkClient, setSdkClient ] = React.useState<SpotifyApi>(SpotifyApi.withUserAuthorization("90e0a81cc8174949b5adbf5e0f85f80c", window.location.origin, [ "user-read-playback-state", "user-modify-playback-state" ]))
  const [ playbackState, setPlaybackState ] = React.useState<PlaybackState | undefined>()
  const [ profile, setProfile ] = React.useState<UserProfile | undefined>()

  React.useEffect(() => {
    if(!sdkClient) return

    // console.log('GET PLAYBACK STATE: ', true)
    sdkClient.player.getPlaybackState().then(setPlaybackState)
    sdkClient.currentUser.profile().then(setProfile)
  }, [])

  return { sdk: sdkClient, playbackState, profile }
}