import React from 'react';
import './App.css';
import { Artist, MaxInt, Page, SimplifiedAlbum } from "@spotify/web-api-ts-sdk";
import { useApi, } from "./hooks/useApi";
import { PlayingItem, useSdk } from "./hooks/useSdk";
import { PlayingAlbum } from "./components/PlayingAlbum";
import { SlClose } from "react-icons/sl";
import useAsyncEffect from "./hooks/useAsyncEffect";


interface IAccessResponse {

}

enum ForcedView {
  NOT_SET,
  ALBUMBS,
  ARTISTS,
  PLAYBACK
}

// const ARTISTS = [ "1l6d0RIxTL3JytlLGvWzYe", "0nNcNNBedN3GKqhlUsAObU" ]

interface ISptFailed {
  title: string
  description: string
}

function App() {
  const { sdk, playbackState: initialPlaybackState, profile } = useSdk()
  const { getMe } = useApi()

  const [ playbackState, setPlaybackState ] = React.useState(initialPlaybackState)
  const [ token, setToken ] = React.useState<{} | undefined>()
  const [ artists, setArtists ] = React.useState<string[]>([])
  const [ artistDetails, setArtistDetails ] = React.useState<Artist[]>()
  const [ selectedArtist, setSelectedArtist ] = React.useState<string | undefined>(undefined)
  const [ selectedAlbum, setSelectedAlbum ] = React.useState<SimplifiedAlbum | undefined>()
  const [ albums, setAlbums ] = React.useState<Page<SimplifiedAlbum> | undefined>(undefined)
  const [ forcedView, setForcedView ] = React.useState<ForcedView>(ForcedView.NOT_SET)
  const [ errorMessage, setErrorMessage ] = React.useState<ISptFailed | undefined>(undefined)
  const [ loadingIndex, setLoadingIndex ] = React.useState({ max: 5, current: 0 })

  const triggerReload = React.useRef(false)

  const auth = async () => {
    const accessToken = await sdk.getAccessToken()
    if (accessToken && accessToken.expires) {
      const expiresIn = (accessToken.expires - Date.now()) / 1000
      if (expiresIn > 60) {
        setToken(accessToken)
        return
      }
    }

    const result = await sdk.authenticate()
    if (result.authenticated && result.accessToken) {
      setToken(result.accessToken)
    }
  }


  const getArtistInformation = async () => {
    if (!artists || artists.length == 0) return
    if (playbackState?.is_playing && forcedView == ForcedView.NOT_SET) return

    const artistInfo = await Promise.all(artists.map(a => sdk.artists.get(a)))
    setArtistDetails(artistInfo)
  }

  const getAlbums = async () => {
    if (!selectedArtist) return
    const albums = await sdk.artists.albums(selectedArtist, undefined, undefined, 50)
    setAlbums(albums)
  }

  const onAlbumSelected = async (a: SimplifiedAlbum) => {
    setSelectedAlbum(a)
    if (a) {
      const success = await playAlbum(a)
      if (success) await updatePlaybackState(loadingIndex.max)
    }
  }

  const updatePlaybackState = async (maxTries: number = 5) => {
    setLoadingIndex({ ...loadingIndex, current: loadingIndex.max - maxTries })

    const state = await sdk.player.getPlaybackState()
    if (state) setPlaybackState(state)
    else if (maxTries > 0) setTimeout(() => updatePlaybackState(maxTries - 1), 1000)
    else {
      setSelectedAlbum(undefined)
      setErrorMessage({ title: 'Fehler beim Abspielen', description: 'Das Album konnte nicht geladen werden. Bitte versuche es erneut abzuspielen.' })
    }
  }


  const getSavedArtists = async () => {
    if (!profile?.id) return

    const { artists } = await getMe(profile.id)
    if (artists) setArtists(artists)
  }
  useAsyncEffect(getSavedArtists, [ profile?.id ])

  const onBack = async () => {
    setForcedView(ForcedView.ARTISTS)
    setSelectedArtist(undefined)
    setSelectedAlbum(undefined)

    if (profile?.id) {
      const { artists } = await getMe(profile.id)
      if (artists) setArtists(artists)
    }
  }

  const playAlbum = async (album: SimplifiedAlbum) => {
    const devices = await sdk.player.getAvailableDevices()
    const jarnoDevice = devices.devices.find(d => d.name.indexOf("KiddyBox") >= 0)
    if (!jarnoDevice?.id) {
      setErrorMessage({ title: "Gerät nicht gefunden", description: "Die Lautsprecher konnte nicht gefunden werden. Bitte starte die Lautsprecher neu und versuche es erneut." })
      setSelectedAlbum(undefined)
      return false
    }

    await sdk.player.startResumePlayback(jarnoDevice.id, album.uri)
    return true
  }

  const loadNextAlbums = async () => {
    if (!selectedArtist || !albums) return
    if (albums.items.length == albums.total) return

    let albumsLeft = albums.total - albums.items.length
    if (albumsLeft <= 0) return

    const amountToLoad = albumsLeft > 50 ? 50 : albumsLeft as MaxInt<50>
    const newAlbs = await sdk.artists.albums(selectedArtist, undefined, undefined, amountToLoad, albums.items.length)

    setAlbums({
      ...albums,
      offset: newAlbs.offset,
      items: [
        ...albums.items,
        ...newAlbs.items
      ]
    })

    triggerReload.current = false
  }

  React.useEffect(() => {
    if (!albums) return
    // console.log(`Loaded albums (${ albums.items.length }/${ albums.total })`)
  }, [ albums ])

  const onScroll = async (e: Event) => {
    if (!selectedArtist || triggerReload.current) return

    const viewHeight = document.documentElement.clientHeight
    const currentTop = document.documentElement.scrollTop
    const fullHeight = document.documentElement.scrollHeight

    if (currentTop + viewHeight > fullHeight * 0.7) {
      triggerReload.current = true
      await loadNextAlbums()
      setTimeout(() => { triggerReload.current = false }, 1000)

    }
  }

  // React.useEffect(() => {
  //   getArtistInformation()
  //     .then(() => {
  //       return getAlbums()
  //     })
  // }, [ artists ])


  React.useEffect(() => {
    auth()
      .then(getSavedArtists)
  }, [])

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [ selectedArtist, triggerReload.current, albums ])


  React.useEffect(() => {
    if (playbackState?.item) {
      const activeItem = playbackState.item as unknown as PlayingItem
      if (activeItem) setSelectedAlbum(activeItem.album as unknown as SimplifiedAlbum)
      setSelectedArtist(activeItem?.album?.artists[0]?.id)
      setForcedView(ForcedView.NOT_SET)
    }
  }, [ playbackState ])

  const gotoCurrentPlayback = () => {
    if (playbackState?.item) {
      const activeItem = playbackState.item as unknown as PlayingItem
      if (activeItem) setSelectedAlbum(activeItem.album as unknown as SimplifiedAlbum)
      setSelectedArtist(activeItem?.album?.artists[0]?.id)
      setForcedView(ForcedView.NOT_SET)
    }
  }

  React.useEffect(() => setPlaybackState(initialPlaybackState), [ initialPlaybackState ])

  useAsyncEffect(getArtistInformation, [ artists ])
  useAsyncEffect(getAlbums, [ selectedArtist ])

  const activeItem = playbackState?.item as unknown as PlayingItem

  return (
    <>
      { errorMessage && (
        <div style={ { position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 99, display: 'flex', alignItems: "center", justifyContent: 'center' } }>
          <div style={ { display: 'inline-flex', backgroundColor: '#FFF', borderRadius: 4, maxWidth: '50vw' } }>
            <div>
              <div style={ { textAlign: 'center', fontWeight: 700, paddingTop: '6px' } }>{ errorMessage?.title }</div>
              <hr />
              <div style={ { padding: '1rem' } }>
                { errorMessage.description }
              </div>
              <hr />
              <div style={ { display: "flex", alignItems: 'center', justifyContent: 'center', padding: '0.2rem 0 0.8rem 0' } }>
                <button onClick={ () => setErrorMessage(undefined) }>Verstanden</button>
              </div>

            </div>

          </div>
        </div>
      ) }


      { !selectedAlbum && token && !selectedArtist && artistDetails && (
        <div className="tile-container">
          { artistDetails.map(a => (
            <div className="tile-artist" key={ a.id }>
              <a href="#" onClick={ () => setSelectedArtist(a.id) }>
                <img src={ a.images[1].url } className="App-logo" alt="logo" />
                <p>{ a.name }</p>
              </a>
            </div>
          )) }
        </div>
      ) }


      { ((forcedView == ForcedView.ALBUMBS && albums) || (token && selectedArtist && albums && !selectedAlbum)) && (
        <>
          <div className="tile-container">
            { albums.items.map((a) => (
              <div className="tile-album" key={ a.id }>
                <a href="#" onClick={ () => onAlbumSelected(a) }>
                  <img src={ a.images[1].url } className="App-logo" alt="logo" />
                  <p>{ a.name }</p>
                </a>
              </div>
            )) }
          </div>
          <SlClose className="btn_back" onClick={ onBack } />
        </>
      ) }

      { selectedAlbum && forcedView == ForcedView.NOT_SET && !playbackState && (
        <>
          <div className="act_wrapper">
            <h2 style={ { color: '#FFF', fontWeight: 400 } }>Aktives Album wird geladen</h2>
            <div className="act_wrapper_row">
              { Array(5).fill(1).map((a, i) => (
                <React.Fragment key={ `act_${ i }` }>
                  <div style={ { height: '20px', width: '20px', border: '1px solid #ccc', borderRadius: '2px', margin: '0 .4rem', backgroundColor: i <= loadingIndex.current ? 'white' : 'transparent' } }></div>
                </React.Fragment>
              )) }
            </div>
          </div>

          <SlClose className="btn_back" onClick={ () => setForcedView(ForcedView.ALBUMBS) } />
        </>
      ) }

      { forcedView == ForcedView.NOT_SET && playbackState && (
        <>
          <PlayingAlbum playbackState={ playbackState } />
          <SlClose className="btn_back" onClick={ () => setForcedView(ForcedView.ALBUMBS) } />
        </>
      ) }

      { playbackState && forcedView != ForcedView.NOT_SET && (
        <div className="fixed_player_footer">
          <a href="#" onClick={ gotoCurrentPlayback }>
            <img src={ activeItem.album.images[0].url } alt={ activeItem.album.name } />
          </a>
        </div>
      ) }

    </>
  );
}

export default App;
